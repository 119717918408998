<template>
  <div>
    <header class="d-md-none mb-3">
      <div class="background-header"></div>
      <div class="content container">
        <div class="row">
          <div class="col d-flex justify-content-between align-items-center">
            <i class="ri-arrow-left-line ri-xl fw-500 text-white" role="button" @click="$router.replace({name: 'ProfilOrder'})"></i>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex align-items-end justify-content-between">
            <div class="order-group d-flex flex-column">
              <span class="order-label fw-400 text-white mb-2">No. Lab</span>
              <span class="order-code fw-700 text-white">{{ offlineDetail[0] ? offlineDetail[0]?.nolab : 'Loading...' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info container px-3">
        <div class="row">
          <div class="col">
            <div class="card shadow border-0">
              <div class="card-body row p-0">
                <div class="info-group col d-flex align-items-center">
                  <div class="initial-wrapper d-flex align-items-center justify-content-center bg-info-500 rounded-circle">
                    <span class="initial-value fw-400 fs-14 lh-18 text-white">
                      {{ offlineDetail[0]?.nama_pasien && getInitialName(offlineDetail[0]?.nama_pasien) }}
                    </span>
                  </div>
                  <div class="value-wrapper d-flex flex-column">
                    <span class="value-wrapper-label fw-400 color-neutral-600">Nama Pasien</span>
                    <label class="value-wrapper-name fw-700 color-neutral-900">{{ offlineDetail[0] ? offlineDetail[0]?.nama_pasien : 'Loading...' }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="px-3">
      <div v-if="loading.detail" class="item-content">
        <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
            <div class="not-empty d-flex flex-column align-items-center pt-3">
                <div class="mb-4">
                  <img src="@/assets/icon/empty.png" alt="">
                </div>
                <div class="fm-poppins text-title-active fw-600 fs-32">Loading..</div>
            </div>
        </div>
      </div>

      <div v-else-if="offlineDetail.length === 0" class="item-content">
        <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
            <div class="bd-highlight mb-4">
              <img src="@/assets/icon/not-found.png" alt="">
            </div>
            <div class="bd-highlight">
              <div class="fm-poppins text-title-active fw-600 fs-32">Tidak Ada Hasil</div>
            </div>
            <div class="bd-highlight w-75 text-center">
              <p class="text-nunito text-body-light fw-400 fs-18">Sepertinya kami tidak dapat menemukan data pesanan Anda.</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row d-none d-md-block mt-3">
          <div class="col-md-7">
            <div class="page-title d-flex justify-content-between align-items-center pb-2">
              <div class="bd-highlight d-flex align-items-center">
                <i class="ri-arrow-left-line pe-4" role="button" @click="$router.replace({name: 'ProfilOrder'})"></i>
                <div class="text-title-active text-poppins fw-600 fs-16">Pesanan</div>
              </div>
              <div class="bd-highlight">
                <div class="fm-nunito text-title-active fw-600 fs-16">{{ offlineDetail[0] ? offlineDetail[0]?.nolab : 'Loading...' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="order">
              <div class="">Data Pasien</div>
              <div class="list-service">
                <div class="service d-flex justify-content-between align-items-center">
                  <div class="service-group gap-2">
                    <div class="service-info">
                      <span class="service-title fw-500 color-neutral-900">Tanggal Periksa: </span>
                      <span class="service-price fw-400 color-neutral-700 mb-0">{{ offlineDetail[0] ? offlineDetail[0]?.tanggal_periksa : 'Loading...' }}</span>
                    </div>
                    <div class="service-info">
                      <span class="service-title fw-500 color-neutral-900">Tanggal Lahir: </span>
                      <span class="service-price fw-400 color-neutral-700 mb-0">{{ offlineDetail[0] ? offlineDetail[0]?.tanggal_lahir : 'Loading...' }}</span>
                    </div>
                    <div class="service-info">
                      <span class="service-title fw-500 color-neutral-900">Jenis Kelamin: </span>
                      <span class="service-price fw-400 color-neutral-700 mb-0">{{ offlineDetail[0] ? getGender(offlineDetail[0]?.jenis_kelamin) : 'Loading...' }}</span>
                    </div>
                    <div class="service-info">
                      <span class="service-title fw-500 color-neutral-900">No HP: </span>
                      <span class="service-price fw-400 color-neutral-700 mb-0">{{ offlineDetail[0] ? offlineDetail[0]?.hp : 'Loading...' }}</span>
                    </div>
                    <div class="service-info">
                      <span class="service-title fw-500 color-neutral-900">Alamat: </span>
                      <span class="service-price fw-400 color-neutral-700 mb-0">{{ offlineDetail[0] ? offlineDetail[0]?.alamat : 'Loading...' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="order">
              <div class="">Pesanan</div>
              <div class="list-service">
                <div
                  v-for="pemeriksaan in offlineDetail[0].pemeriksaan"
                  :key="pemeriksaan.nama_pemeriksaan"
                  class="service d-flex justify-content-between align-items-center"
                >
                  <div class="service-group d-flex align-items-center gap-2">
                    <div class="service-icon">
                      <img
                        src="@/assets/icon/stethoscope-1.svg"
                        alt=""
                      />
                    </div>
                    <div class="service-info">
                      <span class="service-title fw-700 color-neutral-900">{{ pemeriksaan.nama_pemeriksaan }}</span>
                      <p class="service-price fw-400 color-secondary-500 mb-0">Rp. {{ parseFloat(pemeriksaan.harga).toLocaleString('id-ID') }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment bg-neutral-100">
              <div class="total-payment d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <span class="fw-400 fs-12 lh-14 color-neutral-600 mb-1">Total Pembayaran</span>
                  <p class="fw-700 fs-14 lh-24 color-neutral-900 mb-0">
                    {{ offlineDetail ? 'Rp. ' + getTotalPrice(offlineDetail[0].pemeriksaan).toLocaleString('id-ID') : 'Loading ...' }} (Sudah Dibayar)
                  </p>
                </div>
                <div class="icon-wrapper">
                  <img src="@/assets/icon/stamp-paid.svg"/>
                </div>
              </div>
            </div>

            <div class="bottom-bar d-flex gap-3">
              <button @click="getPreviewNota(offlineDetail[0].preview)" class="btn btn-orange w-100 my-md-3 fw-600 fs-14 lh-20 text-white mb-0">
                Preview Nota
              </button>
              <button @click="getDownloadNota(offlineDetail[0].download_pdf)" class="btn btn-orange w-100 my-md-3 fw-600 fs-14 lh-20 text-white mb-0">
                Download Nota
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-delete fade"
    id="modal-preview"
    tabindex="-1"
    aria-labelledby="popup nota" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body">
          <iframe :srcdoc='nota' style="width: 100%; height: 100%;" />
          <!-- <iframe :srcdoc='nota' style="width: 800px;height: 1020px;" /> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger col py-2" data-bs-dismiss="modal">Tutup</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import { offlineDetail, getOfflineDetail, loading, deleteOrder } from '@/core/profile'
import { user } from '@/core/auth'
import axios from '@/plugins/axios'
import { Modal } from 'bootstrap'
import { showNotif } from '@/core/utility'

export default {
  components: { },
  setup () {
    const errorMessage = ref('')
    const route = useRoute()
    const nota = ref('')

    onMounted(async () => {
      try {
        await getOfflineDetail({
          id_pendaftar: user.value.id,
          id: route.params.id_order
        })
      } catch (error) {
        console.error('Error get detail = ', error)
        errorMessage.value = 'error: ' + error.response.data.message
      }
    })

    const getInitialName = (fullname) => {
      const fullName = fullname.split('')
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)

      return initials.toUpperCase()
    }

    const getPreviewNota = async (link) => {
      const { data } = await axios.get(link.replace('https://its.labparahita.com/', 'https://lab.aksamedia.co.id/'))
      if (data) {
        nota.value = data
        const modal = new Modal(
          document.querySelector('#modal-preview')
        )
        modal.show()
      }
    }

    const getDownloadNota = async (link) => {
      try {
        const { data } = await axios.get(link.replace('https://its.labparahita.com/', 'https://lab.aksamedia.co.id/'))
        if (data?.success && data?.url) {
          // open new tab
          const newWindow = window.open()
          newWindow.location = data?.url.replace('https://its.labparahita.com/', 'https://lab.aksamedia.co.id/')

          // kalau mau download tanpa new tab
          // create "a" HTML element with href to file & click
          // const link = document.createElement('a')
          // link.href = data?.url
          // link.setAttribute('download', 'nota.pdf') //or any other extension
          // document.body.appendChild(link)
          // link.click()

          // // clean up "a" element & remove ObjectURL
          // document.body.removeChild(link)
        } else {
          let msg = 'Gagal download nota'
          if (data?.message) {
            msg = data?.message
          }

          // Trigger toast
          showNotif({
            status: 'error',
            message: msg
          })
        }
      } catch (error) {
        console.error('error download nota: ', error)

        let msg = 'Error download nota'
        if (error.response?.data?.message) {
          msg = error.response.data.message
        }

        showNotif({
          status: 'error',
          message: msg
        })

        throw error
      }
    }

    const getTotalPrice = (listPemeriksaan) => {
      let tempPrice = 0
      for (let i = 0; i < listPemeriksaan.length; i++) {
        tempPrice += parseFloat(listPemeriksaan[i].harga)
      }
      return tempPrice
    }

    const getGender = (gender) => {
      if (gender === 'L' || gender === 'l') {
        return 'Laki-laki'
      }

      return 'Perempuan'
    }

    return {
      offlineDetail,
      loading,
      errorMessage,
      deleteOrder,
      nota,
      getInitialName,
      getTotalPrice,
      getGender,
      getPreviewNota,
      getDownloadNota
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 234px;
  position: relative;

  .background-header {
    width: 100%;
    height: 212px;
    position: absolute;
    z-index: 1;
    background: #007A98;
  }

  .content {
    position: absolute;
    z-index: 2;
    height: 80px;

    .row {
      @media (max-width: 767px) {
        height: 100%;
      }

      .order {
        &-label,
        &-date {
          font-size: 14px;
          line-height: 24px;
        }

        &-code {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }

  .info {
    position: absolute;
    z-index: 3;
    bottom: 0;

    .card {
      padding: 12px;
      border-radius: 12px;

      .card-body {
        .info-group {
          gap: 8px;

          .initial-wrapper {
            width: 36px;
            height: 36px;
          }

          .value-wrapper {
            gap: 2px;

            &-label {
              font-size: 12px;
              line-height: 14px;
            }

            &-name {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .info-group:first-child {
          box-shadow: inset -1px 0px 0px rgba(242, 242, 242, 0.8);
        }
      }
    }
  }
}

.location {
  .address-box {
    padding: 9px 8px;
    border-radius: 12px;

    .icon-wrapper {
      width: 34px;
      height: 34px;

      img {
        width: 18px;
        height: auto;
      }
    }

    &-info {
      &-name {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 4px;
      }

      &-address {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.service {
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

  &-icon {
    width: 42px;
    height: 42px;
  }

  &-group {
    padding: 12px 0;
  }

  &-title {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
  }

  &-price {
    font-size: 14px;
    line-height: 24px;
  }
}

.service:last-child {
  box-shadow: unset;
}

.payment {
  padding: 13px 13px 13px 20px;
  margin-bottom: 16px;
  @media (max-width: 520px) {
    margin-bottom: 90px;
  }
}

.notification {
  padding: 10px;
  border-radius: 12px;

  &-desc {
    @media (max-width: 520px) {
      max-width: 281px;
    }
  }
}

.modal-delete {
  .modal-dialog {
    // @media (max-width: 520px) {
    //   position: fixed;
    //   top: auto;
    //   right: auto;
    //   left: auto;
    //   bottom: 0;
    //   margin: 0;
    // }

    .modal-content {
      width: 100% !important;
      padding: 22px 18px;
      border-radius: 20px !important;

      @media (max-width: 520px) {
        border-radius: 20px 20px 0px 0px !important;
      }

      .modal-body {
        .group-button {
          margin-top: 32px;

          button {
            width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.5s ease-out;
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}

// .card-info {
//     border: 1px solid #EFF0F7;
//     box-sizing: border-box;
//     border-radius: 10px;
// }

// .card-order {
//     border: 1px solid #EEEFF7;
//     box-sizing: border-box;
//     border-radius: 10px;
// }

// .location-info .card-info {
//     background: #F7F7FC;
// }

// .icon-bg {
//     width: 2.5rem;
//     border-radius: 8px;
//     padding-top: 0.75rem;
//     padding-bottom: 0.75rem;
// }

// .btn-complete-payment {
//   width: 100%;

//   @media (min-width: 520px) {
//     width: 65%;
//     border-radius: 10px;
//     padding-top: 0.75rem;
//     padding-bottom: 0.75rem;
//   }
// }

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    border-top: 1px solid rgb(240, 240, 240);
  }

  @media (min-width: 521px) {
    margin-bottom: 18px;
  }

  .btn-orange {
    width: 100%;
    padding: 10px 0;
  }
}

// .card.summary-order {
//   border: 1px solid #EEEFF7;
//   box-sizing: border-box;
//   border-radius: 10px;
// }

// .my-iframe {
//   table {
//     height: 1020px;
//     width: 800px;
//   }
// }
</style>
